<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="drawer">
        mdi-chevron-left
      </v-icon>

      <v-icon v-else>
        mdi-chevron-right
      </v-icon>
    </v-btn>

    <v-toolbar-title class="hidden-sm-and-down">
      <v-icon style="color: #509dc8; margin-top: -5px;">
        {{ getRouteIcon($route.name) }}
      </v-icon>
      {{ str[$route.name] ? str[$route.name] : $route.name }}
    </v-toolbar-title>

    <v-spacer />

    <div style="display: flex; align-items: center; margin-bottom: 5px;">
      <span class="mr-2">Dark</span>
      <v-switch v-model="$vuetify.theme.dark" class="ma-0 pa-0" color="secondary" @change="onThemeChanged" hide-details />
    </div>

    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <v-list-item @click="logout()">
            <v-list-item-title>
              {{ str['logout'] }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import Api from '../../../../services/Api'

// Components
import { VHover, VListItem } from 'vuetify/lib'

// Utilities
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'DashboardCoreAppBar',

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(VListItem, {
                attrs: this.$attrs,
                class: {
                  'black--text': !hover,
                  'white--text secondary elevation-12': hover,
                },
                props: {
                  activeClass: '',
                  dark: hover,
                  link: true,
                  ...this.$attrs,
                },
              }, this.$slots.default)
            },
          },
        })
      },
    },
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      str: window.strings ? window.strings : {},
    }
  },
  computed: {
    ...mapState(['drawer']),
  },
  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
    }),
    getRouteIcon(route) {
      let icon = ''
      switch (route) {
        case 'dashboard':
          icon = 'mdi-view-dashboard'
          break
        case 'clients':
          icon = 'mdi-account-group'
          break
        case 'support':
          icon = 'mdi-bug'
          break
      }
      return icon
    },
    goPage(page) {
      if (this.$route.path !== page) {
        this.$router.push({ path: page })
      }
    },
    logout() {
      const self = this
      Api.logout(function () {
        sessionStorage.clear()
        self.$router.replace({ path: '/' })
      })
    },
    onThemeChanged: function () {
      localStorage.setItem('dashboard_theme', this.$vuetify.theme.dark ? 'dark' : 'light')
    },
  },
}
</script>
